import React from "react";

import { Container, Form, Icon, Button } from "semantic-ui-react";
import { pageEnum } from "../../Common";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { getListingUrl } from "../../helpers/common";
import { slugify } from "../../helpers/strings";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { AwsUrl } from "../../helpers/aws";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, right: "20px" }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, left: "10px" }}
      onClick={onClick}
    />
  );
}

function SliderComp(props) {
  const { data } = props;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  function getPrice(long, short, commercional, lettingType) {
    let temp = 0;
    if (lettingType == pageEnum.LongLets) {
      temp = long;
    } else if (lettingType == pageEnum.CommercialLets) {
      temp = commercional;
    } else {
      temp = short;
    }
    return temp;
  }

  function getLettingType(lettingType) {
    switch (lettingType) {
      case pageEnum.CommercialLets:
        return "Commercials";
      case pageEnum.LongLets:
        return "Long Lets";
      case pageEnum.ShortLets:
        return "Short Lets";
      case pageEnum.ResidentialSale:
        return "Residential Sales";
      case pageEnum.CommercialSale:
        return "Commercial Sales";
      case pageEnum.Room:
        return "Room";
      default:
        return "";
    }
  }

  function getAvailable(date) {
    const duration = moment.duration(moment(date).diff(moment()));
    const dayOfMonth = moment(date).date(); // Tarihin gün değeri (1-31 arası bir değer)
  
    if (duration.asDays() <= 0) {
      return "Available Now";
    } else if (duration.asDays() <= 60) {
      return dayOfMonth !== 1 ? "Available Soon" : "Available in Few Months";
    }
  
    return "Available in Few Months";
  }

  const redirectToListing = (
    typeName,
    propertyType,
    locationName,
    location
  ) => {
    const slug = document.location.pathname.substring(1);
    const type = slug.split("-")[slug.split("-").length - 1];
    let url = getListingUrl(parseInt(type));

    if (propertyType) {
      document.location =
        url + "?property-type=" + slugify(typeName) + "-" + propertyType;
    } else if (location) {
      document.location =
        url + "?location=" + slugify(locationName) + "-" + location;
    }
  };
  console.log("DATA: ", data);
  const webView = () => (
    <div
      style={{
        display: "flex",
        position: "absolute",
        bottom: 0,
        width: "100%",
        backgroundColor: "#00000047",
        paddingBottom: 5,
      }}
    >
      <Container style={{ display: "flex", alignItems: "center" }}>
        <Form>
          <Form.Field
            style={{
              marginTop: 10,
              color: "#1caa35",
              fontWeight: "bold",
              fontSize: 16,
              cursor: "pointer",
            }}
            onClick={() => redirectToListing(data.typeName, data.typeId, null)}
          >
            {data?.typeName}
          </Form.Field>
          <Form.Field
            style={{ color: "white", fontWeight: "bold", fontSize: 18 }}
          >
            {data?.title}
          </Form.Field>
          <Form.Field
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: 16,
              cursor: "pointer",
            }}
            onClick={() => redirectToListing(null, null, data.locationName, data.locationId)}
          >
            <Icon
              name="map marker alternate"
              color="green"
              style={{ marginTop: 15 }}
            />
            {data?.locationName}
          </Form.Field>
          <Form.Field
            style={{ color: "white", fontWeight: "bold", fontSize: 13 }}
          >
            Bedrooms: {data?.bedrooms}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bathrooms: {data?.bathrooms}
          </Form.Field>
        </Form>

        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Form>
            <div style={{ minWidth: 500 }}>
              {data?.priceYearly > 0 && (
                <div style={{ float: "right" }}>
                  <Form.Field
                    style={{
                      color: "#1caa35",
                      fontWeight: "bold",
                      fontSize: 25,
                      textAlign: "right",
                    }}
                  >
                    <Icon name="euro" />{" "}
                    {Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                      data?.priceYearly
                    )}
                  </Form.Field>
                  <Form.Field
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: 14,
                      textAlign: "right",
                    }}
                  >
                    {(getLettingType(data?.lettingType) == "Residential Sales" || getLettingType(data?.lettingType) == "Commercial Sales")
                      ? ""
                      : "Yearly"}
                  </Form.Field>
                </div>
              )}
              {data?.isPriceOnRequest == true && (
                <div style={{ float: "right", marginRight: "10%" }}>
                  <Form.Field
                    style={{
                      color: "#1caa35",
                      fontWeight: "bold",
                      fontSize: 20,
                      cursor: "pointer",
                    }}
                  >Price On Request</Form.Field>
                </div>
              )}
              {data?.price > 0 && data?.isPriceOnRequest == false && (
                <div style={{ float: "right", marginRight: "10%" }}>
                  <Form.Field
                    style={{
                      color: "#1caa35",
                      fontWeight: "bold",
                      fontSize: 25,
                      textAlign: "right",
                    }}
                  >
                    <Icon name="euro" />{" "}
                    {Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                      data?.price
                    )}
                  </Form.Field>
                  <Form.Field
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: 14,
                      textAlign: "right",
                    }}
                  >
                    {(getLettingType(data?.lettingType) == "Residential Sales" || getLettingType(data?.lettingType) == "Commercial Sales")
                      ? ""
                      : "Montly"}
                  </Form.Field>
                </div>
              )}
              {data?.priceDaily > 0 && data?.isPriceOnRequest == false && (
                <div style={{ float: "right", marginRight: "10%" }}>
                  <Form.Field
                    style={{
                      color: "#1caa35",
                      fontWeight: "bold",
                      fontSize: 25,
                      textAlign: "right",
                    }}
                  >
                    <Icon name="euro" />{" "}
                    {Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                      data?.priceDaily
                    )}
                  </Form.Field>
                  <Form.Field
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: 14,
                      textAlign: "right",
                    }}
                  >
                    {(getLettingType(data?.lettingType) == "Residential Sales" || getLettingType(data?.lettingType) == "Commercial Sales")
                      ? ""
                      : "Daily"}
                  </Form.Field>
                </div>
              )}
            </div>

            <Form.Field
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "18%",
              }}
            >
              <Button size="mini" color="black">
                {getLettingType(data?.lettingType)}
              </Button>
              <Button
                size="mini"
                color={getAvailable(data?.availableDate) === "Available Now" ? "green" : (getAvailable(data?.availableDate) === "Available Soon"? "orange": "teal")}
              >
                {getAvailable(data?.availableDate)}
              </Button>
            </Form.Field>
          </Form>
        </div>
      </Container>
    </div>
  );

  const mobileView = () => (
    <div
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      <Container style={{ display: "flex", marginTop: 10 }}>
        <Form>
          <Form.Field
            style={{ color: "#1caa35", fontWeight: "bold", fontSize: 16 }}
            onClick={() => redirectToListing(data.typeName, data.typeId, null, null)}
          >
            {data?.typeName}
          </Form.Field>
          <Form.Field style={{ fontWeight: "bold", fontSize: 18 }}>
            {data?.title}
          </Form.Field>

          <Form.Field style={{ fontWeight: "bold", fontSize: 16 }}>
            <Icon
              name="map marker alternate"
              color="green"
              style={{ marginTop: 15 }}
              onClick={() => redirectToListing(null, null, data.locationName, data.locationId)}
            />
            {data?.locationName}
          </Form.Field>
          <Form.Field style={{ fontWeight: "bold", fontSize: 13 }}>
            Bedrooms: {data?.bedrooms}&nbsp;&nbsp;Bathrooms: {data?.bathrooms}
          </Form.Field>
        </Form>

        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Form>
            <Form.Field
              style={{ fontWeight: "bold", fontSize: 16, textAlign: "right" }}
            >
              Ref. No: {data?.id}
            </Form.Field>

            {data?.priceDaily > 0 && (
              <Form.Field
                style={{
                  color: "#1caa35",
                  fontWeight: "bold",
                  fontSize: "x-large",
                  textAlign: "right",
                  marginBottom: "15px",
                }}
              >
                {" "}
                <Icon name="euro" style={{ margin: 0 }} size={"small"} />{" "}
                {Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                  data?.priceDaily
                )}{" "}
                <span style={{ fontSize: "small" }}>/Daily</span>
              </Form.Field>
            )}

            {data?.price > 0 && (
              <Form.Field
                style={{
                  color: "#1caa35",
                  fontWeight: "bold",
                  fontSize: "x-large",
                  textAlign: "right",
                  marginBottom: "15px",
                }}
              >
                {" "}
                <Icon name="euro" style={{ margin: 0 }} size={"small"} />{" "}
                {Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                  data?.price
                )}{" "}
                <span style={{ fontSize: "small" }}>
                  {data?.lettingType != 4 ? "/Monthly" : ""}
                </span>
              </Form.Field>
            )}

            {data?.priceYearly > 0 && (
              <Form.Field
                style={{
                  color: "#1caa35",
                  fontWeight: "bold",
                  fontSize: "x-large",
                  textAlign: "right",
                  marginBottom: "15px",
                }}
              >
                {" "}
                <Icon name="euro" style={{ margin: 0 }} size={"small"} />{" "}
                {Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                  data?.priceYearly
                )}{" "}
                <span style={{ fontSize: "small" }}>/Yearly</span>
              </Form.Field>
            )}

            <Form.Field
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: 14,
                textAlign: "right",
              }}
            >
              {getLettingType(data?.lettingType) == "For Sale" ? "" : "Montly"}
            </Form.Field>

            <Form.Field style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button size="mini" color="black">
                {getLettingType(data?.lettingType)}
              </Button>
              <Button
                size="mini"
                color={getAvailable(data?.availableDate) === "Available Now" ? "green" : (getAvailable(data?.availableDate) === "Available Soon"? "orange": "teal")}
              >
                {getAvailable(data?.availableDate)}
              </Button>
            </Form.Field>
          </Form>
        </div>
      </Container>
    </div>
  );

  return (
    <div className="property-detail-silider" style={{ position: "relative" }}>
      {props.photos?.length === 0 ? (
        <div style={{ height: "300px" }}></div>
      ) : (
        <Slider {...settings}>
          {props.photos?.map((item) => {
            let photoUrl = AwsUrl + item.imageCompressSource;
            return (
              <div key={item.id}>
                <div
                  tag="a"
                  index={item.id}
                  onClick={() => props.setModalSliderVisible()}
                >
                  <img
                    className="slider-image"
                    src={photoUrl}
                    style={{ height: "50vh", width: "100%" }}
                  />
                </div>
              </div>
            );
          })}
        </Slider>
      )}
      {isMobile ? mobileView() : webView()}
    </div>
  );
}

export default SliderComp;
