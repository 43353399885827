import React, { useCallback, useState, useEffect } from 'react';
import { Grid, Container, Breadcrumb, Form, Dropdown, Checkbox, Button, Search, Message } from 'semantic-ui-react'
import GoogleMapReact from 'google-map-react'
import api from '../../api'
import { pageEnum } from '../../Common';
import _, { debounce } from 'lodash';
import PhoneInput from 'react-phone-input-2'
import ReCAPTCHA from "react-google-recaptcha"

const lettingType = [
  { key: pageEnum.LongLets, text: 'Long Lets', value: pageEnum.LongLets },
  { key: pageEnum.ShortLets, text: 'Short Lets', value: pageEnum.ShortLets },
  { key: pageEnum.CommercialLets, text: 'Commercials', value: pageEnum.CommercialLets },
  { key: pageEnum.ResidentialSale, text: 'Residential Sales', value: pageEnum.ResidentialSale },
  { key: pageEnum.CommercialSale, text: 'Commercial Sales', value: pageEnum.CommercialSale },
  { key: pageEnum.Room, text: 'Room', value: pageEnum.Room },
]

const googleMapKey = "AIzaSyCny9n4aoP6a6csRPxw-CsZq3ly5hJhBZU";

const MyProperties = () => {

  const [options, setOptions] = useState({
    propertyTypes: [],
    areas: [],
    locations: [],
    locationsFullData: [],
    features: []
  })

  const [form, setForm] = useState({
    lettingTypes: [],
    areas: [],
    locations: [],
    features: [],
    photos: []
  })

  const [latitude, setLatitude] = useState(35.911292);
  const [longitude, setLongitude] = useState(14.489585);

  const [center, setCenter] = useState({
    lat: 35.911292,
    lng: 14.489585
  })

  // const [placeId, setPlaceId] = useState('');
  const [address, setAddress] = useState('');
  const [googleApiResults, setGoogleApiResults] = useState({
    searchData: [],
    placesData: {},
  });
  const [googleApiSearchLoading, setGoogleApiSearchLoading] = useState(false);

  const [fileError, setFileError] = useState(false);


  useEffect(() => {
    const load = async () => {
      const o = await api.properties.getFilterOptions()
      if (o?.result /* options.statusCode == 200 */) {
        const propTypes = mapPropertyTypes(o?.result.propertyTypes)
        const areas = mapAreas(o?.result.areas)
        const locations = mapLocations([areas[0]?.value], o?.result.locations)
        setOptions({
          ...options,
          propertyTypes: propTypes,
          areas,
          locations,
          locationsFullData: o?.result.locations,
          features: o?.result.features,
        })
      }
    }

    load()
  }, [])

  const mapPropertyTypes = (data) => {
    const uniquePTypes = [];
    data.map((item) => {
      var findItem = uniquePTypes.find((x) => x.id === item.id);
      if (!findItem) uniquePTypes.push(item);
    });
    let propTypes = []
    uniquePTypes?.map(type => { propTypes.push({ key: type.id.toString(), value: type.id, text: type.name }) })
    return propTypes
  }

  const mapAreas = (data) => {
    let areas = []
    data?.map(type => {
      areas.push({ key: type.id.toString(), value: type.id, text: type.name })
    })
    return areas
  }

  const mapLocations = (areaIds, data) => {
    let locations = []
    data?.map(type => {
      if (areaIds.indexOf(type.group.id) > -1) {
        locations.push({ key: type.id.toString(), value: type.id, text: type.name, groupid: type.groupId })
      }
    })
    return locations
  }

  const debounceSearchAddress = useCallback(debounce(async (q) => {
  const geocoder = new window.google.maps.places.AutocompleteService()

    await geocoder.getPlacePredictions({ input: q, })
      .then(res => {
        setGoogleApiResults({
          ...googleApiResults,
          searchData: res.predictions
        })
      }).catch(e => {
        console.log(e)
      }).finally(() => {
        setGoogleApiSearchLoading(false)
      })

  }, 500), []);

  const getLocation = useCallback(async (q) => {

    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ placeId: q })
      .then(res => {

        setGoogleApiResults({
          ...googleApiResults,
          placesData: res.results[0]
        })

        setCenter({ lat: res.results[0].geometry.location.lat(), lng: res.results[0].geometry.location.lng() })

      }).catch(e => {

      })

  }, []);

  const AnyReactComponent = () => (
    <img alt="" src="https://cdn0.iconfinder.com/data/icons/20-flat-icons/128/location-pointer.png" style={{ height: 30 }} />
  );


  const messagePositive = () => (
    <Message positive>
      <Message.Header>Successful</Message.Header>
      <p>
        Your request has been sent successfully.
      </p>
    </Message>
  )

  const messageWarning = () => (
    <Message negative>
      <Message.Header>Error</Message.Header>
      <p>Please fill the required fields. <br></br>"Full Name", "Contact Number"</p>
    </Message>
  )

  const messageNegative = () => (
    <Message negative>
      <Message.Header>Error</Message.Header>
      <p>An unexpected error has occurred. Please try again.</p>
    </Message>
  )

  const [selectedBedroom, setSelectedBedroom] = React.useState(0)
  const renderButtonBedroom = (text, code) => {
    let basic = true, positive = false
    if (text === "1" || text === "2" || text === "3" || text === "4" || text === "5" || text === "6" || text === "7" || text === "8" || text === "9" || text === "9+") {
      if (selectedBedroom === code) {
        basic = false
        positive = true
      }
      return <Button basic={basic} positive={positive} onClick={() => setSelectedBedroom(code)}>{text}</Button>
    }

  }

  const [selectedBathroom, setSelectedBathroom] = React.useState(0)
  const renderButtonBathroom= (text, code) => {
    let basic = true, positive = false
    if (text === "1" || text === "2" || text === "3" || text === "4" || text === "5" || text === "6" || text === "7" ) {
      if (selectedBathroom === code) {
        basic = false
        positive = true
      }
      return <Button basic={basic} positive={positive} onClick={() => setSelectedBathroom(code)}>{text}</Button>
    }

  }

  const [captch, setCaptch] = React.useState(true)
  const [captchValue, setCaptchValue] = React.useState('')
  const onChange = (value) => {
    console.log("Captcha value:", value);
    if (value !== null) {
      setCaptchValue(value)
      setCaptch(false)
    } else {
      setCaptch(true)
    }
  }
  const [isSuccess, setIsSuccess] = React.useState(0)//ilk hali 0, başarılı 1, başarısız 2
  const saveProperty = async () => {
    

    if (!form?.fullName || !form?.contactNumber) {
      setIsSuccess(3);
      setCaptchValue(null);
      return;
    }

    setCaptch(true)

    let formData = new FormData()
    formData.append('FullName', form?.fullName)
    formData.append('ContactNumber', form?.contactNumber)
    if(form?.lettingTypes.length>=0)
    for (var i = 0; i < form?.lettingTypes.length; i++) { formData.append('LettingTypes[]', form?.lettingTypes[i]); }

    formData.append('LongLetPrice', form?.longLetPrice === undefined ? 0 : form?.longLetPrice)
    formData.append('ShortLetPrice', form?.shortLetPrice === undefined ? 0 : form?.shortLetPrice)
    formData.append('CommercialLetPrice', form?.commercialLetPrice === undefined ? 0 : form?.commercialLetPrice)
    formData.append('SalePrice', form?.salePrice === undefined ? 0 : form?.salePrice)
    formData.append('RoomPrice', form?.roomPrice === undefined ? 0 : form?.roomPrice)
    formData.append('PropertyType', form?.propertyTypes ? form?.propertyTypes : 999)
    formData.append('Bedrooms', selectedBedroom)
    formData.append('Bathrooms', selectedBathroom)
    formData.append('AvailableDate', form?.availableDate ? form?.availableDate : '1900-01-01')
    formData.append('LocationId', form?.locationId ? form?.locationId : 999);
    formData.append('Address', address)
    latitude && formData.append('Latitude', latitude.toString().replace('.', ','))
    longitude && formData.append('Longitude', longitude.toString().replace('.', ','))

    var _features = form?.features.map(s => s.id);
    for (var x = 0; x < _features.length; x++) { formData.append('Features[]', _features[x]); }
    formData.append('Description', form?.description?form?.description:'')

    form.photos.forEach(element => { formData.append('Photos', element); });

    formData.append('CaptchValue', captchValue)

    const result = await api.requestPropertyAdd.requestPropertyAdd(formData)

    if (result == null) {
      setCaptch(false);
      setIsSuccess(2);
      return;
    }

    console.log(result);
    if (result.statusCode === 200) {
      setIsSuccess(1)
      window.scrollTo(0, 0);
      window.location.href = "../../ThankYou";
    } else {
      setIsSuccess(2)
    }
    setCaptch(false)
  }

  return (
    <Container style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>

      <Breadcrumb>
        <Breadcrumb.Section href="/">Home</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Add My Property</Breadcrumb.Section>
      </Breadcrumb>
      {isSuccess !== 1 && (
        <Form style={{ marginTop: 30 }}>

          <Form.Group widths='equal'>
            <Form.Input required name="fullName" fluid label='Full Name' id="fullName" placeholder='Full Name' value={form?.fullName} onChange={event => setForm({ ...form, fullName: event.currentTarget.value })} />

            <Form.Field>
              <label >Contact Number <label color="red">*</label></label>
              <PhoneInput required name="contactNumber" placeholder='Contact Number' id="contactNumber" country={'mt'} inputProps={{ name: 'phone', required: true }} value={form?.contactNumber} onChange={phone => setForm({ ...form, contactNumber: phone })} inputStyle={{ paddingLeft: '45px' }} />
            </Form.Field>
          </Form.Group>

          <Form.Group widths='equal'>
            <Form.Field>
              <h5 style={{ marginBottom: 5 }}>Property Types</h5>
              <Dropdown
                name="PropertyTypes"
                placeholder='Choose Property Type' fluid selection
                options={options.propertyTypes}
                aria-label="choose-property-types"
                onChange={(e, d) => setForm({ ...form, propertyTypes: d.value })}
                value={form.propertyTypes}
              />
            </Form.Field>
            <Form.Field>
              <label>Available From</label>
              <input name="availableDate" id="availableDate" placeholder='Available Date' min={new Date().toISOString().slice(0, -1)} type="date" value={form?.availableDate} onChange={e => setForm({ ...form, availableDate: e.currentTarget.value })} />
            </Form.Field>
          </Form.Group>

          
          <Form.Group widths='equal'>
              <Form.Field>
                <h5 style={{ marginBottom: 5 }}>Letting Types</h5>
                <Dropdown
                  name="lettingTypes"
                  placeholder="Select" fluid multiple selection
                  options={lettingType}
                  aria-label="choose-letting-types"
                  value={form.lettingTypes}
                  onChange={(e, { value }) => {
                    setForm({ ...form, lettingTypes: value })
                  }}
                />
              </Form.Field>
              <Form.Field>
                <h5 style={{ marginBottom: 5 }}>Location</h5>
                <Dropdown
                  placeholder='Choose Location ' fluid selection
                  options={options.locations}
                  aria-label="choose-location"
                  onChange={(e, d) => setForm({ ...form, locationId: d.value })}
                />
              </Form.Field>
          </Form.Group>
          <Form.Group widths='5'>
            <Form.Input name="longLetPrice" type="number"
              fluid label='Long Let Price (€)' id="longLetPrice"
              value={form?.longLetPrice}
              disabled={form.lettingTypes.find(x => x === pageEnum.LongLets) ? false : true}
              onChange={event => setForm({ ...form, longLetPrice: event.currentTarget.value })} />

            <Form.Input name="shortLetPrice" type="number"
              fluid label='Short Let Price (€)' id="shortLetPrice"
              value={form?.shortLetPrice}
              
              disabled={form.lettingTypes.find(x => x === pageEnum.ShortLets) ? false : true}
              onChange={event => setForm({ ...form, shortLetPrice: event.currentTarget.value })} />

            <Form.Input name="commercialLetPrice" type="number"
              fluid label='Commercial Let Price (€)' id="commercialLetPrice"
              value={form?.commercialLetPrice}
              disabled={form.lettingTypes.find(x => x === pageEnum.CommercialLets) ? false : true}
              onChange={event => setForm({ ...form, commercialLetPrice: event.currentTarget.value })} />

            <Form.Input name="salePrice" type="number"
              fluid label='Sale Price (€)' id="salePrice"
              value={form?.salePrice}
              disabled={form.lettingTypes.find(x => x === pageEnum.ResidentialSale || x === pageEnum.CommercialSale) ? false : true}
              onChange={event => setForm({ ...form, salePrice: event.currentTarget.value })} />

            <Form.Input name="roomPrice" type="number"
              fluid label='Room Price (€)' id="roomPrice"
              value={form?.roomPrice}
              disabled={form.lettingTypes.find(x => x == pageEnum.Room) ? false : true}
              onChange={event => setForm({ ...form, roomPrice: event.currentTarget.value })} />

          </Form.Group>

          <Form.Group widths='equal'>
                <Form.Field>
                  <label>Bedrooms</label>
                  <div style={{ fontSize: 'x-small' }}>
                    {renderButtonBedroom('1', 1)}
                    {renderButtonBedroom('2', 2)}
                    {renderButtonBedroom('3', 3)}
                    {renderButtonBedroom('4', 4)}
                    {renderButtonBedroom('5', 5)}
                    {renderButtonBedroom('6', 6)}
                    {renderButtonBedroom('7', 7)}
                    {renderButtonBedroom('8', 8)}
                    {renderButtonBedroom('9', 9)}
                    {renderButtonBedroom('9+', 99)}
                  </div>
                </Form.Field>
                <Form.Field>
                <label>Bathrooms</label>
                  <div style={{ fontSize: 'x-small' }}>
                    {renderButtonBathroom('1', 1)}
                    {renderButtonBathroom('2', 2)}
                    {renderButtonBathroom('3', 3)}
                    {renderButtonBathroom('4', 4)}
                    {renderButtonBathroom('5', 5)}
                    {renderButtonBathroom('6', 6)}
                    {renderButtonBathroom('7', 7)}
                  </div>
                </Form.Field>
          </Form.Group>

          <span style={{ fontWeight: 'bold' }}>Address</span>
          <Grid style={{ marginBottom: 0, marginTop: -10 }}>
            <Grid.Column width={16}>
              <Search
                fluid
                showNoResults={false}
                style={{ backgroundColor: '', }}
                resultRenderer={(e) => {
                  return <span>{e.description}</span>
                }}
                loading={googleApiSearchLoading}
                placeholder='Search...'
                onResultSelect={(e, data) => {
                  setAddress(data?.result.description);
                  getLocation(data?.result.place_id);
                }}
                onSearchChange={(e) => {
                  setGoogleApiSearchLoading(true);
                  setAddress(e.target.value);
                  debounceSearchAddress(e.target.value);
                }}
                results={googleApiResults.searchData}
                value={address}
              />
            </Grid.Column>
          </Grid>

          <div style={{ height: '500px', width: '100%', marginBottom: 20 }}>
            <GoogleMapReact
              //onDragEnd={() => searchByCoordinateDragEnd()}
              bootstrapURLKeys={{ key: googleMapKey }}
              defaultCenter={center}
              center={center}
              defaultZoom={14}
              yesIWantToUseGoogleMapApiInternals={true}
              onClick={e => {
                setGoogleApiResults({
                  ...googleApiResults,
                  placesData: {
                    geometry: {
                      location: {
                        lat: e.lat,
                        lng: e.lng
                      }
                    }
                  }
                });
                setLatitude(e.lat);
                setLongitude(e.lng);
              }}
            // onChange={onChange}
            // onChildClick={_onChildClick}
            // onGoogleApiLoaded={() => setMapLoaded(true)}
            >

              {googleApiResults.placesData && !_.isEmpty(googleApiResults.placesData) &&
                <AnyReactComponent
                  lat={googleApiResults?.placesData?.geometry.location.lat}
                  lng={googleApiResults?.placesData?.geometry.location.lng}

                />

              }

            </GoogleMapReact>

          </div>

          <h4 style={{ marginTop: 10, marginBottom: 30 }}>Features</h4>
          <Grid style={{ marginBottom: 20 }}>
            {
              options?.features?.sort((a, b) => (a.name > b.name ? 1 : -1)).map((x, index) => (
                <Grid.Column computer={"3"} mobile="3" key={"features" + index.toString()}>
                  <Checkbox key={"check-features" + index.toString()}
                    onChange={(e, d) => {

                      let features = form.features;
                      if (d.checked) {
                        let k = options?.features.find(y => y.id === d.id);
                        features.push(k);
                        setForm({ ...form, features: features })
                      } else {
                        features = features.filter(y => y.id !== d.id)
                        setForm({ ...form, features: features })

                      }
                    }}
                    label={x.name}
                    id={x.id}
                  />
                </Grid.Column>
              ))
            }
          </Grid>

          <Form.Field>
            <Form.TextArea label='Comment' name="description" id="description" onChange={e => setForm({ ...form, description: e.currentTarget.value })} />
          </Form.Field>

          <Form.Input fluid label='Photos'>
            <div style={{ flexDirection: 'column' }}>
              {fileError && <h3 style={{ color: "red" }}>Maximum 20 mb and 20 downloadable</h3>}
              <input type="file" id="photos" name="photos"
                multiple accept="image/*"
                onChange={e => {
                  const t = _.values(e.target.files);

                  if (t.length > 20) {
                    setFileError(true)
                    return;
                  }

                  let totalSize = 0;
                  t.forEach(element => {
                    totalSize += element.size;
                  });
                  if (totalSize > 20971520) { //20mb 
                    setFileError(true)
                    return
                  }

                  setForm({ ...form, photos: t })
                }}
              />
            </div>
          </Form.Input>

          <ReCAPTCHA
            sitekey="6LdZIDIaAAAAAMmQ0DWPLAlsyPjBtJLk1wc2-4Vo"
            onChange={onChange}
          />
          <Form.Button style={{ marginTop: 10, width: 300 }}
            disabled={captch}
            color="green" onClick={saveProperty}>Save Property</Form.Button>
        </Form>
      )}
      {
        isSuccess === 1 ? messagePositive() : isSuccess === 2 ? messageNegative() : isSuccess === 3 ? messageWarning() : null
      }
    </Container>
  )
}

export default MyProperties;