import React from 'react';
import { CarouselProvider, Image, Slide, Slider } from "pure-react-carousel";
import SliderForm from './SliderForm';
import "pure-react-carousel/dist/react-carousel.es.css";

function SliderComp() {
  //const URL = 'https://easyrent.s3.eu-central-1.amazonaws.com/ProjectFiles/';
  const URL = '../img/';

  const slides = [
    { id: 1, imageSource: 'slider5.jpg', title1: 'Discover Your Perfect Property Rental in Malta - Simplifying Every Step of the Journey!'}
  ];

  // CSS stillerini burada inline olarak tanımlıyoruz
  const styles = {
    imageContainer: {
      position: 'relative',
      height: '100%',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column', // Bu, metinlerin alt alta gelmesini sağlar
      alignItems: 'center', // Metinleri ortalar
      justifyContent: 'center',
      position: 'absolute', // Resmin üstünde olacak
      top: '30%',
      left: '50%',
      transform: 'translate(-90%, -50%)',
      color: 'white',
      textAlign: 'left',
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Metni daha görünür hale getirmek için gölge ekliyoruz
    },
    sliderContainer: {
      position: 'relative',
      height: '100%',
    },
    title: {
      marginBottom: '10px', // Başlıklar arasına boşluk ekliyoruz
      fontSize: '2.4vw', // Yazı boyutunu ekran genişliğine göre ayarlıyoruz
      color: 'white',
      textAlign: 'left'
    },
  };

  const slider = () => (
    <CarouselProvider
      naturalSlideWidth={1}
      naturalSlideHeight={0.4}
      totalSlides={1}
      infinite
      interval={4000}
      isPlaying
    >
      <Slider>
        {slides?.map(item => (
          <Slide tag="a" key={item.id}>
            <div style={styles.imageContainer}>
              <Image
                className="slider-image"
                src={URL + item.imageSource}
                style={{ height: '100%' }}
              />
              <div style={styles.textContainer}>
                <h1 style={styles.title}>{item.title1}</h1>
              </div>
            </div>
          </Slide>
        ))}
      </Slider>

      <SliderForm />
    </CarouselProvider>
  );

  return (
    <div style={styles.sliderContainer}>
      {slider()}
    </div>
  );
}

export default SliderComp;
