import React from 'react'
import { Grid, Container, Icon, Breadcrumb, Form, Message } from 'semantic-ui-react'
import ReCAPTCHA from "react-google-recaptcha"
import api from '../../api'
import { setRefId, getRefId } from '../../helpers/cookie'

import { Helmet } from 'react-helmet'

const Contact = () => {

  const [form, setForm] = React.useState({
    name: '', email: '', phone: '', comment: '', captcha: null
  })
  const [captch, setCaptch] = React.useState(true)
  const [isSuccess, setIsSuccess] = React.useState(0)//ilk hali 0, başarılı 1, başarısız 2

  React.useEffect(() => {
    const script = document.createElement("script")
    script.async = true

    script.type = "application/ld+json"

    const codeStr = `
    "@context": "https://schema.org",
    "@type": "RealEstateAgent",
    "name": "Easy Rent",
    "image": "https://www.easyrent.com.mt/img/logo-green.png",
    "@id": "",
    "url": "https://www.easyrent.com.mt/",
    "telephone": "99998330",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "420 Triq D'Argens",
      "addressLocality": "Gżira",
      "postalCode": "",
      "addressCountry": "MT"
    } ,
    "sameAs": [
      "https://www.facebook.com/easyrentmt/",
      "https://www.instagram.com/easyrent.malta/",
      "https://www.youtube.com/channel/UCjo81yeSg4l4JJ3qkl79Hfw",
      "https://www.linkedin.com/company/easyrent-malta/"
    ] 
`
    script.innerHTML = codeStr

    document.body.appendChild(script);

    let url = window.location.href
    let ref = url.split('?ref=')

    if (ref.length > 1) {
      setRefId(ref[1])
    }
  }, [])

  const onChange = (value) => {
    console.log("Captcha value:", value);
    if (value !== null) {
      setForm({ ...form, captcha: value })
      setCaptch(false)
    }
  }

  const sendNow = async () => {
    const model = { ...form, refId: getRefId() === null ? 0 : getRefId() }
    const res = await api.contact.postContact(model)
    if (res.statusCode === 200) {
      setIsSuccess(1)
      window.location.href = "../../ThankYou";
    } else {
      setIsSuccess(2)
    }
  }

  const messagePositive = () => (
    <Message positive>
      <Message.Header>Successful</Message.Header>
      <p>
        Your request has been sent successfully
      </p>
    </Message>
  )

  const messageNegative = () => (
    <Message negative>
      <Message.Header>Error</Message.Header>
      <p>An unexpected error has occurred. Please try again.</p>
    </Message>
  )

  return (
    <Container style={{ paddingTop: '2rem' }}>

      <Helmet>
        <title>Property to Rent Malta | Real Estate Agent in Malta</title>
        <meta name="description" content="Long Lets and Short Lets Apartments in Malta. Easy Rent Malta – One of the best Real Estate agent in Malta. The easiest way to find your ideal property in Malta." />
        <meta name="keywords" content="apartment for rent, rent in malta, real estate in malta, accommodation in malta, long lets apartments, short lets apartments, commercial property, property for sale" />

      </Helmet>

      <Breadcrumb>
        <Breadcrumb.Section link href="/">Home</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Contact Us</Breadcrumb.Section>
      </Breadcrumb>

      <Grid style={{ paddingTop: '5rem', paddingBottom: '10rem' }}>
        <Grid.Column computer={10} mobile={16}>
          <h1>CONTACT US</h1>
          <p>
            You are looking for apartment, want to rent one or just have consultation from Real Estate expert? Just fill the form and we will contact you. Kindly note, that here at EasyRent we take your privacy seriously and will only use your personal information to administer your account and provide the products, services and information you have requested from us. EasyRent does not sell or share your personal information.
          </p>
          {
            isSuccess === 1 ? messagePositive() : isSuccess === 2 ? messageNegative() : null
          }
          <Form style={{ marginTop: 30 }}>
            <Form.Group widths='equal'>
              <Form.Input fluid label='Name' placeholder='Name' onChange={e => setForm({ ...form, name: e.currentTarget.value })} />
              <Form.Input fluid label='Email' placeholder='Email' onChange={e => setForm({ ...form, email: e.currentTarget.value })} />
            </Form.Group>
            <Form.Group widths='2'>
              <Form.Input label='Phone' placeholder='Phone' onChange={e => setForm({ ...form, phone: e.currentTarget.value })} />
            </Form.Group>
            <Form.TextArea label='Comment' placeholder='Tell us more about you...' onChange={e => setForm({ ...form, comment: e.currentTarget.value })} />

            <ReCAPTCHA
              sitekey="6LdZIDIaAAAAAMmQ0DWPLAlsyPjBtJLk1wc2-4Vo"
              onChange={onChange}
            />

            <Form.Button disabled={captch} onClick={sendNow} color="green">SEND NOW</Form.Button>
          </Form>

        </Grid.Column>
        <Grid.Column computer={6} mobile={16}>
          <h1>ADDRESS</h1>
          <br />
          <Icon name="map pin" /> 420 Triq D'Argens, Il-Gżira
          <br /><br />
          <Icon name="envelope open" /> <a href="mailto:info@easyrent.com.mt">info@easyrent.com.mt</a>
          <br /><br />
          <Icon name="phone" /> <a href="tel:99998330">99998330</a>
          <br /><br />
          <a href="https://www.facebook.com/easyrentmt/" target="_blank"><Icon name="facebook" /></a>
          <a href="https://www.instagram.com/easyrent.malta" target="_blank"><Icon name="instagram" /></a>

          <br /><br />

          <iframe title="title0"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12926.32340799068!2d14.494368!3d35.90828!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x130e4547e11df0ed%3A0xf2230dfc44f4f60a!2s420%20Triq%20D&#39;Argens%2C%20Il-G%C5%BCira!5e0!3m2!1sen!2smt!4v1611011889212!5m2!1sen!2smt" width="400" height="300" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0">

          </iframe>

        </Grid.Column>
      </Grid>
    </Container>
  )
}

export default Contact
