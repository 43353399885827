import React from "react";

const ThankYouPage = () => {
    const pageStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundImage: "url('https://source.unsplash.com/1600x900/?nature,thankyou')",
        backgroundSize: "cover",
        backgroundPosition: "center"
    };

    const boxStyle = {
        background: "rgba(255, 255, 255, 0.9)",
        padding: "30px",
        borderRadius: "10px",
        boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
        textAlign: "center",
        maxWidth: "400px"
    };

    const headingStyle = {
        color: "#4CAF50",
        fontSize: "28px"
    };

    const textStyle = {
        fontSize: "18px",
        color: "#333"
    };

    const buttonStyle = {
        display: "inline-block",
        marginTop: "20px",
        padding: "10px 20px",
        background: "#4CAF50",
        color: "white",
        textDecoration: "none",
        borderRadius: "5px",
        fontSize: "18px",
        transition: "0.3s"
    };

    return (
        <div style={pageStyle}>
            <div style={boxStyle}>
                <h1 style={headingStyle}>Thank You!</h1>
                <p style={textStyle}>Your form has been successfully submitted.</p>
                <a href="/" style={buttonStyle}>Back to Home</a>
            </div>
        </div>
    );
};

export default ThankYouPage;