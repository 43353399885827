import React from 'react'
import { Button, Modal, Form, TextArea, Item, Grid, Message } from 'semantic-ui-react'
import api from '../../api'
import ReCAPTCHA from "react-google-recaptcha"
import { getRefId } from '../../helpers/cookie'
import PhoneInput from 'react-phone-input-2' 

const ContactAgentModal = ({ isOpen, onClose, data, form, setForm }) => {

  
  const [isSuccess, setIsSuccess] = React.useState(0)//ilk hali 0, başarılı 1, başarısız 2
  const [captch, setCaptch] = React.useState(true)
  const [captchValue, setCaptchValue] = React.useState('')


  const URL = "https://easyrent.s3.eu-central-1.amazonaws.com/Users/"

  React.useEffect(() => {
    setIsSuccess(0)
    setForm({ ...form, message: '' })
  }, [isOpen])

  const contactAgentPost = async () => {
    setCaptch(true)

    const model = {
      message: form?.message,
      name: form?.name,
      phone: form?.phoneNumber,
      email: form?.email,
      propertyId: form?.id,
      captcha: captchValue,
      refId: getRefId() === null ? 0 : getRefId()
    }
    const result = await api.contactAgent.contactAgent(model)
    if (result.statusCode === 200) {
      setIsSuccess(1);
      window.location.href = "../../ThankYou";
    } else {
      setIsSuccess(2)
    }
    setCaptch(false)
  }

  const onChange = (value) => {
    console.log("Captcha value:", value);
    if (value !== null) {
      setCaptchValue(value)
      setCaptch(false)
    } else {
      setCaptch(true)
    }
  }

  const messagePositive = () => (
    <Message positive>
      <Message.Header>Successful</Message.Header>
      <p>
        Your request has been sent successfully
      </p>
    </Message>
    
  )

  const messageNegative = () => (
    <Message negative>
      <Message.Header>Error</Message.Header>
      <p>An unexpected error has occurred. Please try again.</p>
    </Message>
  )


  return (
    <div>
      <Modal
        closeIcon
        size={"mini"}
        open={isOpen}
        onClose={() => onClose()}
      >
        <Modal.Header>Contact Agent</Modal.Header>
        {
          isSuccess === 1 ? messagePositive() : isSuccess === 2 ? messageNegative() : null
        }
        <Modal.Content>
          <p>Enter your contact details, and we'll let the rental manager know you want to submit an application. If they're interested, they'll contact you with next steps.</p>

          <Form>
            <Form.Field>
              <TextArea placeholder="Your Message" value={form?.message} onChange={(event, data) => setForm({ ...form, message: data.value })} />
            </Form.Field>
            <Form.Field>
              <input placeholder='Your Name' value={form?.name} onChange={event => setForm({ ...form, name: event.currentTarget.value })} />
            </Form.Field>
            <Form.Field>


              <PhoneInput
                country={'mt'}
                value={form?.phoneNumber}
                onChange={phone => setForm({ ...form, phoneNumber: phone })}
                inputStyle={{ paddingLeft: '45px' }}
              />

              {/* <input placeholder='Your Phone Number' value={form?.phoneNumber} onChange={event => setForm({ ...form, phoneNumber: event.currentTarget.value })} /> */}
            </Form.Field>
            <Form.Field>
              <input placeholder='Your Email' value={form?.email} onChange={event => setForm({ ...form, email: event.currentTarget.value })} />
            </Form.Field>
            <ReCAPTCHA
              sitekey="6LdZIDIaAAAAAMmQ0DWPLAlsyPjBtJLk1wc2-4Vo"
              onChange={onChange}
            />

            <Button style={{ marginTop: 10 }} disabled={captch} onClick={contactAgentPost} fluid type='button' color="green">Send Request</Button>
          </Form>

          <Item.Group>
            <Item>
              <Item.Content verticalAlign='middle'>
                <Item.Header>
                  <Grid divided>
                    <Grid.Column width="6">
                      <Item.Image circular size='tiny' src={data?.profilePicture === null ? '/img/avatar.jpg' : URL + data?.profilePicture} />
                    </Grid.Column>
                    <Grid.Column width="10">
                      {data?.firstName + ' ' + data?.lastName} <br />
                      <a href={`tel:${data?.mobilePhone}`}>
                        <span style={{ fontSize: 'medium', color: 'gray' }}>
                          {data?.mobilePhone} <br />
                          {/* {data?.email} */}
                          info@easyrent.com.mt
                        </span>
                      </a>
                    </Grid.Column>
                  </Grid>
                </Item.Header>
              </Item.Content>
            </Item>
          </Item.Group>
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default ContactAgentModal
